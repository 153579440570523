$('#home-image-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    useTransform: true,
    autoplay: false,
    speed: 700,
    asNavFor: '#home-text-slider',
    cssEase: 'cubic-bezier(0.57, 0, 0.18, 1)',
    nextArrow: $('#next'),
    prevArrow: $('#prev'),
});


$('#home-text-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    useTransform: true,
    speed: 1000,
    asNavFor: '#home-image-slider',
    cssEase: 'cubic-bezier(0.57, 0, 0.18, 1)',
});