import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const slideSections = [...document.getElementsByClassName("slide-effects")];

function slideEffect(img, contentBox, mediaBox) {
  gsap.registerPlugin(ScrollTrigger);

  // gsap.fromTo(
  //   img,
  //   {
  //     filter: "blur(0px)",
  //   },
  //   {
  //     duration: 1.2,
  //     ease: "power2",
  //     filter: "blur(4px)",
  //     scrollTrigger: {
  //       trigger: img,
  //       start: "80% bottom",
  //       end: "bottom center",
  //       scrub: false,
  //       toggleActions: "play none none none",
  //     },
  //   }
  // );

  gsap.fromTo(
    mediaBox,
    { x: "-=500", opacity: 0 },
    {
      x: 0,
      opacity: 1,
      duration: 4,
      ease: "easeInOut",
      scrollTrigger: {
        trigger: mediaBox,
        scrub: true,
        strat: "top bottom",
        end: "80% 65%",
      },
    }
  );

  gsap.fromTo(
    contentBox,
    { x: "+=500", opacity: 0 },
    {
      x: 0,
      opacity: 1,
      duration: 4,
      ease: "easeInOut",
      scrollTrigger: {
        trigger: contentBox,
        scrub: true,
        strat: "top bottom",
        end: "80% 65%",
      },
    }
  );
}

window.addEventListener('load', function () {

  if (slideSections) {

    slideSections.forEach((section) => {
      const img = $(section)
      .find(".page-image")
      .get();
    const contentBox = $(section)
      .find(".content-box")
      .get();
    const mediaBox = $(section)
      .find(".media-box")
      .get();

      slideEffect(img[0], contentBox[0], mediaBox[0]);

    });
    
  }

})







const images = document.querySelectorAll(".float");



$(document).mousemove(function(event) {
  const xPos = event.clientX / $(window).width() - 0.5,
    yPos = event.clientY / $(window).height() - 0.5;

  images.forEach((item) => {
    gsap.to(item, 0.6, {
      rotationY: 5 * xPos,
      rotationX: 5 * yPos,
      ease: "easeInOut",
      transformPerspective: 900,
      transformOrigin: "center",
    });
  });
});


